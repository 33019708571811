import React from "react";
import { startCase } from "lodash";
import { Controller, useForm } from "react-hook-form";
import { useModal } from "react-modal-hook";
import {
  checkWarningValidVisibility,
  TABLE_NAMES,
} from "common/utils/queryBuilder";
import {
  AccountPropertyWarningDefinitionInput,
  PropertyWarningGroup,
  PropertyWarningSeverity,
  useGetSavedViewsQuery,
} from "../../../../generated/graphql";
import { useStatusToasts } from "../../../../hooks/useStatusToasts";
import { AuthContext } from "../../../Authorization/AuthContext";
import { PublicPortal } from "../../../Authorization/types";
import {
  ButtonSection,
  Container,
  ContentSection,
  FormSection,
  HeaderSection,
  InputRow,
  PrimaryButtons,
} from "../../../Common/__styles__/Modal";
import { Button } from "../../../Common/Button";
import Modal from "../../../Common/Modal";
import { Label, Radio, Select } from "../../../Inputs";
import { Text } from "../../../Inputs/react-hook-form";
import { EditableWarning } from "../../../Warnings/WarningList";
import { RadioRow } from "./__styles__/Warnings";

interface WarningMutationFormStructure {
  group: PropertyWarningGroup;
  title: string;
  message: string;
  severity: PropertyWarningSeverity;
  isPublic: string;
}

export const useWarningMutationModal = ({
  warning,
  onSave,
}: {
  warning?: EditableWarning;
  onSave: ({
    id,
    data,
  }: {
    id?: string;
    data: AccountPropertyWarningDefinitionInput;
  }) => void;
}) => {
  const [showWarningMutationModal, hideWarningMutationModal] = useModal(
    () => (
      <Modal onRequestClose={hideWarningMutationModal}>
        <WarningMutationForm
          onClose={hideWarningMutationModal}
          onSave={onSave}
          warning={warning}
        />
      </Modal>
    ),
    [warning]
  );

  return [showWarningMutationModal, hideWarningMutationModal] as const;
};

export const defaultIsPublicValue = ({
  warning,
  publicPortal,
}: {
  warning?: EditableWarning;
  publicPortal?: Pick<PublicPortal, "enabled">;
}) => {
  if (warning) {
    return warning.isPublic.toString();
  }

  if (publicPortal && !publicPortal.enabled) {
    return "false";
  }

  return undefined;
};

export const WarningMutationForm = ({
  warning,
  onClose,
  onSave,
}: {
  warning?: EditableWarning;
  onClose: () => void;
  onSave: ({
    id,
    data,
  }: {
    id?: string;
    data: AccountPropertyWarningDefinitionInput;
  }) => void;
}) => {
  const { account } = React.useContext(AuthContext);
  const { addErrorToast } = useStatusToasts();

  const { data: savedViewsResponse, loading: loadingSavedViews } =
    useGetSavedViewsQuery({
      variables: { table: TABLE_NAMES.PROPERTIES },
      fetchPolicy: "cache-and-network",
    });

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isValid, isDirty },
  } = useForm<WarningMutationFormStructure>({
    //this validates the form after every change is made
    //to enable the submit button when all required fields are filled out
    mode: "onChange",
    defaultValues: {
      ...warning,
      isPublic: defaultIsPublicValue({
        warning,
        publicPortal: account?.publicPortal,
      }),
    },
  });

  const onSubmit = handleSubmit(async warningData => {
    if (warning?.id) {
      const hiddenWarnings = checkWarningValidVisibility({
        accountPropertyWarningDefinition: {
          id: warning.id,
          isPublic: warningData.isPublic === "true",
        },
        queryDescriptions:
          savedViewsResponse?.account?.savedViews
            .filter(view => view.hiddenFromPublic === false)
            .map(view => view.query) ?? [],
      });

      if (hiddenWarnings.length) {
        addErrorToast(
          "Updating this warning will break currently saved table views. Modify these views before making changes to the warning."
        );
        return;
      }
    }

    const data: AccountPropertyWarningDefinitionInput = {
      title: warningData.title,
      message: warningData.message,
      group: warningData.group,
      severity: warningData.severity,
      isPublic: warningData.isPublic === "true",
    };

    onSave({ id: warning?.id, data });

    onClose();
  });

  const disableIsPublic =
    warning &&
    (warning.isSensitive ||
      (account?.publicPortal.hideSISD &&
        warning.group === PropertyWarningGroup.IMPROVEMENT_WARNING));

  return (
    <Container overflows>
      <HeaderSection>
        <h1>{!!warning ? "Edit" : "Create"} account warning</h1>
      </HeaderSection>
      <FormSection>
        <ContentSection>
          <InputRow>
            <Controller
              control={control}
              name="group"
              rules={{ required: "Required field" }}
              render={({ field, fieldState }) => (
                <Select
                  size="small"
                  name="group"
                  value={field.value}
                  error={fieldState.error?.message}
                  label="Warning group"
                  options={Object.values(PropertyWarningGroup).map(group => {
                    return { value: group, label: startCase(group) };
                  })}
                  onChange={field.onChange}
                  disabled={warning && !warning.isCustom}
                  required
                />
              )}
            />
          </InputRow>
          <InputRow>
            <Text
              placeholder="Add warning title..."
              error={errors.title?.message}
              label="Warning title"
              size="small"
              {...register("title", {
                required: "Required field",
              })}
              data-testid="title"
              disabled={warning && !warning.isCustom}
              required
            />
          </InputRow>
          <InputRow>
            <Text
              placeholder="Add warning text..."
              error={errors.message?.message}
              label="Warning text"
              size="small"
              {...register("message", {
                required: "Required field",
              })}
              disabled={warning && !warning.isCustom}
              data-testid="message"
              required
            />
          </InputRow>
          <RadioRow>
            <Label text="Warning severity" required />
            <Controller
              control={control}
              name="severity"
              rules={{ required: "Required field" }}
              render={({ field, fieldState }) => (
                <Radio
                  name="severity"
                  value={field.value}
                  error={fieldState.error?.message}
                  options={[
                    { value: PropertyWarningSeverity.HIGH, text: "High (red)" },
                    {
                      value: PropertyWarningSeverity.MEDIUM,
                      text: "Medium (yellow)",
                    },
                    { value: PropertyWarningSeverity.LOW, text: "Low (grey)" },
                  ]}
                  onChange={field.onChange}
                  required
                  hasBackground={true}
                />
              )}
            />
          </RadioRow>
          {account?.publicPortal.enabled && (
            <RadioRow>
              <Label text="Visibility" required />
              <Controller
                control={control}
                name="isPublic"
                rules={{ required: "Required field" }}
                render={({ field, fieldState }) => (
                  <Radio
                    name="isPublic"
                    value={field.value?.toString()}
                    error={fieldState.error?.message}
                    options={[
                      { value: "true", text: "Shown to Public" },
                      {
                        value: "false",
                        text: "Hidden from Public (internal only)",
                      },
                    ]}
                    onChange={field.onChange}
                    disabled={disableIsPublic}
                    required
                    hasBackground={true}
                  />
                )}
              />
            </RadioRow>
          )}
        </ContentSection>
        <ButtonSection>
          <PrimaryButtons>
            <Button styleVariant="secondary" onClick={onClose} size="medium">
              Cancel
            </Button>
            <Button
              styleVariant="primary"
              onClick={onSubmit}
              size="medium"
              disabled={!isValid || !isDirty || loadingSavedViews}
            >
              Save
            </Button>
          </PrimaryButtons>
        </ButtonSection>
      </FormSection>
    </Container>
  );
};
